<template>
  <v-footer dark paddless id="footer" color="#29526b" class="pt-6">
    <v-container>
      <!-- <snackbar :attr="snackbar" /> -->
      <v-row no-gutters>
        <v-col cols="auto">
          <v-img
            max-width="180"
            src="../assets/reentry-white@2x.png"
            class="mb-2 mr-4"
          ></v-img>
        </v-col>
        <v-col cols="4">
          <p class="address">
            1300 Joseph E. Boone Blvd NW
            <br />
            Atlanta, GA 30314
            <br />
            (404) 952-1201
          </p>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="4" style="text-align:right">
          <v-btn v-if="!isLoggedIn" text @click="clearVuex()" class="grey--text caption">
            <vue-fontawesome :icon="['fas', 'cog']" class="mr-1"/>Reset</v-btn>

          <!-- <v-btn icon @click="clearVuex()">
            <vue-fontawesome :icon="['fas', 'cog']" />
          </v-btn> -->
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="text-center">
          <v-divider></v-divider>
          <div class="pt-4 grey--text caption footie">
            &copy; {{ new Date().getFullYear() }} City of Refuge, Inc. All
            Rights Reserved. Developed by
            <a href="https://9degrees.com" target="_blank">9degrees llc</a>
          </div>
          <!-- <div class="pt-4">
            &copy; {{ new Date().getFullYear() }} Applied Research Services,
            Inc. All rights reserved.
          </div> -->
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
// import Snackbar from '@/components/Snackbar.vue'

export default {
  // components: { Snackbar },
  name: 'Footerbar',
  data() {
    return {
      // snackbar: {
      //   model: false,
      //   color: '',
      //   message: '',
      //   icon: ['fas', 'exclamation-triangle'],
      //   timeout: 3000
      // },
      linksFirst: [
        {
          title: 'About SRS',
          url: '#',
          target: true
        },
        {
          title: 'Locations',
          url: '#',
          target: true
        },
        {
          title: 'Careers',
          url: '#',
          target: true
        },
        {
          title: 'Contact Us',
          url: '#',
          target: true
        }
      ],
      linksSecond: [
        {
          title: 'Events',
          url: '#',
          target: true
        },
        {
          title: 'News',
          url: '#',
          target: true
        },
        {
          title: 'Privacy Policy',
          url: '#',
          target: true
        }
      ]
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn || false
    },
  },
  methods: {
    clearVuex() {
      localStorage.clear()
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/login').catch(err => {
          console.log(err)
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
#footer {
  font-size: 0.875rem;
  padding: 1rem;
  // color: #aaaaaa;
}
.address {
  color: #cccccc;
  line-height: 1.3rem;
}
.address b {
  color: #aaaaaa;
}
.footer-links {
  line-height: 1.5rem;
  list-style-type: none;
}
.footer-links li a,
.footie a {
  color: #bbbbbb;
}
.footer-links li a:hover {
  color: #f3f3f3;
}
.footie a {
  color: #bbbbbb;
  border-bottom: 1px dotted #999999;
  transition-duration: 1s;
}
.footie a:hover {
  color: #ffffff;
}
</style>
