import Vue from 'vue'
import VueRouter from 'vue-router'
import { isJwtExpired } from 'jwt-check-expiration';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home'),
    meta: {
      title: 'Home'
    }
  },
  // {
  //   name: 'About',
  //   path: '/about',
  //   component: () => import('@/views/About'),
  //   meta: {
  //     title: 'About'
  //   }
  // }
  {
    path: '/login',
    component: () => import('@/views/Login'),
    meta: {
      title: 'Login'
    }
  },
  {
    path: '/logout',
    component: () => import('@/components/Logout'),
    meta: {
      title: 'Logout'
    }
  },
  {
    path: '/reset-password',
    component: () => import('@/views/ResetPassword'),
    meta: {
      title: 'Reset Password'
    }
  },
  {
    path: '/users',
    component: () => import('@/views/Users'),
    meta: {
      title: 'Users',
      requiresAuth: true
    }
  },
  {
    path: '/citizens',
    component: () => import('@/views/Citizens'),
    meta: {
      title: 'Citizens',
      requiresAuth: true
    }
  },
  {
    path: '/citizen/:id',
    component: () => import('@/views/Citizen'),
    meta: {
      title: 'Citizen Profile',
      requiresAuth: true
    }
  },
  {
    path: '/participation/:id',
    component: () => import('@/views/Participation'),
    meta: {
      title: 'Participation',
      requiresAuth: true
    }
  },
  {
    path: '/new-participation/citizen/:id',
    component: () => import('@/views/NewParticipation'),
    meta: {
      title: 'New Participation',
      requiresAuth: true
    }
  }
  // {
  //   path: '/activities',
  //   // path: '/company/:id',
  //   component: () => import('@/views/Activities'),
  //   meta: {
  //     title: 'Customers'
  //     // requiresAuth: true
  //   }
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `reentryHUB - ${to.meta.title}`
  next()
})
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (localStorage.getItem('token') && isJwtExpired(localStorage.getItem('token'))) {
          next({
              path: '/logout',
          })
      } else {
          next()
      }
      if (localStorage.getItem('token') == null) {
          next({
              path: '/login',
          })
      } else {
          next()
      }
  } else {
      next()
  }
})
export default router
