<template>
  <div class="text-center">
    <v-menu v-model="menu" :close-on-content-click="false" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <span class="text--secondary caption mr-2">Partner</span>
        <v-btn
          v-if="isAdmin"
          large
          dark
          color="#E87432"
          v-bind="attrs"
          v-on="on"
          ><vue-fontawesome
            size="lg"
            :icon="['fas', 'hands-helping']"
            class="mr-2"/>{{ agencyName
          }}<vue-fontawesome :icon="['far', 'chevron-down']" class="ml-2"
        /></v-btn>
        <v-btn v-else large dark color="#E87432"
          ><vue-fontawesome
            size="lg"
            :icon="['fas', 'hands-helping']"
            class="mr-2"
          />{{ agencyName }}</v-btn
        >
      </template>

      <v-card>
        <v-card-subtitle>Select Agency</v-card-subtitle>
        <v-divider></v-divider>

        <v-list dense>
          <v-list-item-group>
            <v-list-item v-for="(agency, index) in agencies" :key="index">
              <v-list-item-content>
                <v-list-item-title @click="setAgency(index)"
                  >{{ agency.name }}
                  <vue-fontawesome
                    v-if="agency.id === $store.state.agency.id"
                    :icon="['far', 'check']"
                    class="ml-2"/></v-list-item-title
              ></v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <!-- <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="menu = false">
            Cancel
          </v-btn>
          <v-btn color="primary" text @click="menu = false">
            Save
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-menu>
  </div>
</template>
<script>
export default {
  name: 'AgencySwitcher',
  props: {
    agencies: Array,
    isAdmin: Boolean
  },
  data: () => ({
    fav: true,
    menu: false,
    message: false,
    hints: true
  }),
  computed: {
    agencyName() {
      return this.$store.state.agency.name || null
    }
  },
  methods: {
    async setAgency(index) {
      // this.agencyLoading = true
      const agency = {
        id: this.agencies[index].id,
        name: this.agencies[index].name,
        abbr: this.agencies[index].abbr
      }
      this.$store.dispatch('setAgency', { agency })
      this.menu = false
      // console.log(agency)
    }
  }
}
</script>
