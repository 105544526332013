import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

// const loginpath = window.location.pathname
// console.log(loginpath)

// const instance = axios.create({
//   baseURL: process.env.VUE_APP_FM_DATA_API,
//   timeout: 1000,
//   headers: {'X-Custom-Header': 'foobar'}
// });

export default new Vuex.Store({
  // plugins: [createPersistedState()],
  plugins: [createPersistedState({
    storage: window.sessionStorage,
  })],
  components: {
    axios
  },
  state: {
    appName: 'theHUB',
    status: '',
    isLoggedIn: false,
    user: {},
    // token: localStorage.getItem('token') || '',
    token: '',
    agency: {},
    agencies: []
  },
  mutations: {
    SET_REDIRECT_PATH(state, path) {
      state.redirectPath = path
    },
    AUTH_REQUEST(state) {
      state.status = 'loading'
    },
    AUTH_SUCCESS(state, user) {
      state.appName = 'reentryHUB'
      state.status = 'success'
      state.isLoggedIn = true
      state.redirectPath = ''
      // state.isSandbox = true
      // state.token = user.user.token
      state.user = user.user
      // localStorage.addItem('token')
      state.agency = user.user.agency
      state.agencies = user.user.agencies
    },
    SET_AGENCY(state, agency) {
      state.agency = agency.agency
    },
    SET_AGENCIES(state, agencies) {
      state.agencies = agencies.agencies
    },
    LOGOUT(state) {
      state.isLoggedIn = false
      state.user = ''
      state.token = ''
      state.agency = ''
      state.agencies = ''
    }
  },
  actions: {
    login({ commit }, user) {
      commit('AUTH_SUCCESS', user)
    },
    logout({ commit }) {
      localStorage.removeItem('token')
      // localStorage.clear()
      localStorage.removeItem('vuex')
      commit('LOGOUT')
    },
    setAgency({ commit }, agency) {
      commit('SET_AGENCY', agency)
    },
    setAgencies({ commit }, agencies) {
      commit('SET_AGENCIES', agencies)
    }
  },
  getters: {
    isLoggedIn: state => !!state.isLoggedIn
  }
})
